import Link from 'next/link'

import { resolveHref } from '@/sanity/lib/utils'
import type { CallToActionType } from '@/types'

import { BookingButton } from '../ui/button'

interface CallToActionProps extends CallToActionType {
  children?: React.ReactNode
  className?: string
  withoutLabel?: boolean
  // variant?: ButtonProps['variant']
  onClick?: (e: any) => void
  onMouseEnter?: (e: any) => void
  onMouseLeave?: (e: any) => void
  onTouchStart?: (e: any) => void
}

const CallToAction = ({
  _type,
  label,
  linkType,
  linkInternal,
  linkExternal,
  linkEmail,
  linkPhone,
  linkProduct,
  children,
  className,
  withoutLabel,
  ...props
}: CallToActionProps) => {
  switch (linkType) {
    case 'external':
      if (!linkExternal) return null

      return (
        <a
          href={linkExternal.url}
          target={linkExternal.newWindow ? '_blank' : '_self'}
          className={className}
          {...props}
        >
          {withoutLabel ? null : label}
          {children}
        </a>
      )

    case 'internal':
      if (!linkInternal?.reference) return null

      const isTemporaryExternal =
        linkInternal.reference._type === 'product' ||
        linkInternal.reference._type === 'productVariant' ||
        linkInternal.reference._type === 'collection'

      return (
        <Link
          className={className}
          href={resolveHref(linkInternal.reference)}
          {...props}
          target={isTemporaryExternal ? '_blank' : '_self'}
        >
          {withoutLabel ? null : label}
          {children}
        </Link>
      )
    case 'email':
      if (!linkEmail?.email) return null

      return (
        <a href={'mailto:' + linkEmail.email} target="_blank" className={className} {...props}>
          {withoutLabel ? null : label}
          {children}
        </a>
      )
    case 'phone':
      if (!linkPhone?.phone) return null

      return (
        <a href={'tel:' + linkPhone.phone} target="_blank" className={className} {...props}>
          {withoutLabel ? null : label}
          {children}
        </a>
      )
    case 'bookTable':
      return (
        <BookingButton className={className} variant="ghost" {...props}>
          {withoutLabel ? null : label ? label : 'Book a table'}
          {children}
        </BookingButton>
      )
    case 'none':
    default:
      if (children) {
        return (
          <div className={className}>
            {withoutLabel ? null : label}
            {children}
          </div>
        )
      }
      return null
  }
}

export default CallToAction
