import type { PortableTextBlock } from 'next-sanity'

import { CustomPortableText } from '@/components/shared/custom-portable-text'
import { slugifyTitle } from '@/lib/utils'
type ContactTitleCopyProps = {
  title?: string
  copy?: PortableTextBlock[]
}
export function ContactTitleCopy({ copy, title }: ContactTitleCopyProps) {
  return (
    <div>
      <div id={title ? slugifyTitle(title) : ''}>
        {title && <h3 className="h3">{title}</h3>}
        {copy && <CustomPortableText className="pr-body-2 py-3 text-justify" value={copy} />}
      </div>
    </div>
  )
}
