'use client'

import Image from 'next/image'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useOnClickOutside } from 'usehooks-ts'

import useTailwindBreakpoint from '@/hooks/use-tailwind-breakpoint'

type TooltipOverlayProps = {
  children: React.ReactNode
  title: string
  copy: string
}

const TooltipOverlay = ({ title, copy, children }: TooltipOverlayProps) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const [tooltipOffset, setTooltipOffset] = useState(0)
  const isDesktop =  useTailwindBreakpoint('md');

  const ref = useRef<HTMLSpanElement>(null)

  const updateTooltipOffset = useCallback(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      const tooltipWidth = isDesktop ? 414 : 330;
      const viewportWidth = window.innerWidth;
      const xAxisPositionLeft = rect.left;
      if (viewportWidth - xAxisPositionLeft < tooltipWidth) {
        setTooltipOffset(tooltipWidth - (viewportWidth - xAxisPositionLeft));
      } else {
        setTooltipOffset(0);
      }
    }
  }, [isDesktop]);

  useEffect(() => {
    updateTooltipOffset();

    window.addEventListener('resize', updateTooltipOffset);

    return () => {
      window.removeEventListener('resize', updateTooltipOffset);
    };
  }, [updateTooltipOffset]);

  const onClick = () => {
    setShowTooltip((prev) => !prev)
  }

  const onClickOutside = () => {
    setShowTooltip(false)
  }

  useOnClickOutside(ref, onClickOutside)

  return (
    <span ref={ref} className="group relative inline-flex">
      {showTooltip && (
        <div style={{transform: `translateX(-${tooltipOffset}px)`}} className={`absolute -left-5 bottom-9 z-50 w-[330px] bg-black md:w-[414px]`}>
          <div className="flex flex-col gap-2.5 p-5 text-white">
            <div className="font-sans text-12 font-bold">{title}</div>
            <div className="font-serif text-16 leading-6 -tracking-2 md:text-18">{copy}</div>
          </div>
          <div className='w-fit' style={{transform: `translateX(${tooltipOffset}px)`}}>
          <Image
            width={15}
            height={8}
            src="/assets/tooltip-triangle.svg"
            alt=""
            className="translate-x-6 translate-y-2 scale-[3]"
          />
          </div>
        </div>
      )}
      <span
        onClick={onClick}
        className="group-hover:text-gray-900 relative inline-block cursor-pointer whitespace-nowrap text-black transition duration-300"
      >
        <span className="absolute -inset-x-1 bottom-[2px] left-0 right-0 -z-10 h-1 bg-orange transition-all duration-300 group-hover:h-full"></span>
        {children}
      </span>
    </span>
  )
}

export default TooltipOverlay
