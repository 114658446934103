'use client'
import { zodResolver } from '@hookform/resolvers/zod'
import error from 'next/error'
import Link from 'next/link'
import type { PortableTextBlock } from 'next-sanity'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { CreateEmailResponse } from 'resend'
import { z } from 'zod'

import { ContactTitleCopy } from '@/components/modules/contact-listing/contact-title-copy'
import Button from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import Icon from '@/components/ui/icon'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Textarea } from '@/components/ui/textarea'
import { postCustomer } from '@/lib/atreemo'

import { sendContactFormEmail } from './actions'

export const ContactFormSchema = z.object({
  subject: z.string().min(1, {
    message: 'Subject is required',
  }),
  cafe: z.string(),
  firstName: z.string().min(1, {
    message: 'First name is required',
  }),
  lastName: z.string().min(1, {
    message: 'Last name is required',
  }),
  email: z.string().email('Please enter a valid email address'),
  message: z.string(),
  marketingOptIn: z.boolean(),
  recommend: z.string(),
})

type EnquiriesFormProps = {
  enquiriesTitle?: string
  enquiriesCopy?: PortableTextBlock[]
  className?: string
}

export function EnquiriesForm({ enquiriesTitle, enquiriesCopy, className }: EnquiriesFormProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<false | string>(false)
  const [response, setResponse] = useState<CreateEmailResponse | null>(null)
  const form = useForm<z.infer<typeof ContactFormSchema>>({
    resolver: zodResolver(ContactFormSchema),
    defaultValues: {
      subject: '',
      cafe: '',
      firstName: '',
      lastName: '',
      email: '',
      message: '',
      marketingOptIn: false,
      recommend: '',
    },
  })
  const watchSubject = form.watch('subject')

  const subjectOptions = [
    {
      value: 'A Visit',
      label: 'A café visit',
    },
    {
      value: 'A store order',
      label: 'A store order',
    },
    {
      value: 'A delivery order',
      label: 'A delivery order',
    },
    {
      value: 'Group booking enquiry',
      label: 'Group booking enquiry',
    },
    {
      value: 'Something else',
      label: 'Something else',
    },
  ]

  const cafesOptions = [
    {
      value: 'battersea',
      label: 'Battersea',
    },
    {
      value: 'canary-wharf',
      label: 'Canary Wharf',
    },
    {
      value: 'carnaby',
      label: 'Carnaby',
    },
    {
      value: 'covent-garden',
      label: 'Covent Garden',
    },
    {
      value: 'kensington',
      label: 'Kensington',
    },
    {
      value: 'kings-cross',
      label: 'Kings Cross',
    },
    {
      value: 'shoreditch',
      label: 'Shoreditch',
    },
    {
      value: 'birmingham',
      label: 'Birmingham',
    },
    {
      value: 'edinburgh',
      label: 'Edinburgh',
    },
    {
      value: 'manchester',
      label: 'Manchester',
    },
  ]

  const wouldRecommendOptions = [
    {
      value: '10',
      label: '10 - definitely',
    },
    {
      value: '9',
      label: '9',
    },
    {
      value: '8',
      label: '8',
    },
    {
      value: '7',
      label: '7',
    },
    {
      value: '6',
      label: '6',
    },
    {
      value: '5',
      label: '5',
    },
    {
      value: '4',
      label: '4',
    },
    {
      value: '3',
      label: '3',
    },
    {
      value: '2',
      label: '2',
    },
    {
      value: '1',
      label: '1 - no way',
    },
  ]

  const onSubmit = async (data: z.infer<typeof ContactFormSchema>) => {
    try {
      setIsLoading(true)
      const res = await sendContactFormEmail(data)

      if (!res || res instanceof Error) {
        throw new Error('Failed to submit form. Please try again!')
      }

      // submit to atreemo
      if (data.marketingOptIn) {
        const customerResponse = await postCustomer(
          {
            name: `${data.firstName} ${data.lastName}`,
            emailAddress: data.email,
            marketingOptIn: true,
          },
          'Contact Us',
        )

        if (!customerResponse || customerResponse instanceof Error) {
          throw new Error('Failed to submit form. Please try again!')
        }

        if (!customerResponse.ResponseStatus) {
          const message = customerResponse.Errors?.map((error) => error.ErrorDescription).join(', ')
          throw new Error(message || 'Failed to submit form. Please try again!')
        }
      }

      setResponse(res)
      form.reset()
    } catch (e) {
      setIsError(e.message || 'Failed to submit form. Please try again!')
      console.error(e, 'error')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className={className}>
      {enquiriesTitle && enquiriesCopy && (
        <ContactTitleCopy title={enquiriesTitle} copy={enquiriesCopy} />
      )}
      <Form {...form}>
        <form className="grid-container mt-4 gap-5" onSubmit={form.handleSubmit(onSubmit)}>
          <div className="col-span-full pt-2">
            <FormField
              control={form.control}
              name="subject"
              render={({ field }) => (
                <FormItem className="relative">
                  <FormLabel className="field-name absolute -top-[6px] left-3 z-10 bg-beige px-1 font-bold">
                    What are you contacting us about?
                  </FormLabel>
                  <FormControl>
                    <Select onValueChange={field.onChange} value={field.value} required>
                      <FormControl>
                        <SelectTrigger className="rounded-none border-beige-dark bg-transparent !outline-none focus:ring-0 focus:ring-offset-0 focus-visible:ring-0 focus-visible:ring-offset-0">
                          <SelectValue placeholder="..." />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent className="border-gray-light bg-beige">
                        {subjectOptions.map((option) => (
                          <SelectItem key={option.value} value={option.value}>
                            {option.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          {watchSubject === 'A Visit' && (
            <div className="col-span-full pt-2">
              <FormField
                control={form.control}
                name="cafe"
                render={({ field }) => (
                  <FormItem className="relative">
                    <FormLabel className="field-name absolute -top-[6px] left-3 z-10 bg-beige px-1 font-bold">
                      Which location did you visit?
                    </FormLabel>
                    <FormControl>
                      <Select onValueChange={field.onChange} value={field.value} required>
                        <FormControl>
                          <SelectTrigger className="rounded-none border-beige-dark bg-transparent !outline-none focus:ring-0 focus:ring-offset-0 focus-visible:ring-0 focus-visible:ring-offset-0">
                            <SelectValue placeholder="..." />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent className="border-gray-light bg-beige">
                          {cafesOptions.map((option) => (
                            <SelectItem key={option.value} value={option.value}>
                              {option.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          )}
          <div className="col-span-full pt-2 md:col-span-10">
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem className="relative">
                  <FormLabel className="field-name absolute -top-[6px] left-3 z-10 bg-beige px-1 font-bold">
                    First Name*
                  </FormLabel>
                  <FormControl>
                    <Input
                      className="focus:ring-unset rounded-none border-beige-dark bg-transparent !outline-none focus:ring-offset-0 focus-visible:ring-0 focus-visible:ring-offset-0"
                      type="text"
                      placeholder="First Name"
                      {...field}
                      required
                      autoComplete="firstName"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="col-span-full pt-2 md:col-span-10">
            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem className="relative">
                  <FormLabel className="field-name absolute -top-[6px] left-3 z-10 bg-beige px-1 font-bold">
                    Last Name*
                  </FormLabel>
                  <FormControl>
                    <Input
                      className="rounded-none border-beige-dark bg-transparent !outline-none focus:ring-0 focus:ring-offset-0 focus-visible:ring-0 focus-visible:ring-offset-0"
                      type="text"
                      placeholder="Last Name"
                      {...field}
                      required
                      autoComplete="lastName"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="col-span-full pt-2">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem className="relative">
                  <FormLabel className="field-name absolute -top-[6px] left-3 z-10 bg-beige px-1 font-bold">
                    Email Address*
                  </FormLabel>
                  <FormControl>
                    <Input
                      className="rounded-none border-beige-dark bg-transparent !outline-none focus:ring-0 focus:ring-offset-0 focus-visible:ring-0 focus-visible:ring-offset-0"
                      type="email"
                      placeholder="Email"
                      {...field}
                      required
                      autoComplete="email"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="col-span-full pt-2">
            <FormField
              control={form.control}
              name="recommend"
              render={({ field }) => (
                <FormItem className="relative">
                  <FormLabel className="field-name absolute -top-[6px] left-3 z-10 bg-beige px-1 font-bold">
                    Would you recommend us to a friend?
                  </FormLabel>
                  <FormControl>
                    <Select onValueChange={field.onChange} value={field.value} required>
                      <FormControl>
                        <SelectTrigger className="rounded-none border-beige-dark bg-transparent !outline-none focus:ring-0 focus:ring-offset-0 focus-visible:ring-0 focus-visible:ring-offset-0">
                          <SelectValue placeholder="..." />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent className="border-gray-light bg-beige">
                        {wouldRecommendOptions.map((option) => (
                          <SelectItem key={option.value} value={option.value}>
                            {option.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="col-span-full pt-2">
            <FormField
              control={form.control}
              name="message"
              render={({ field }) => (
                <FormItem className="relative">
                  <FormLabel className="field-name absolute -top-[6px] left-3 z-10 bg-beige px-1 font-bold">
                    Message
                  </FormLabel>
                  <FormControl>
                    <Textarea
                      className="w-full rounded-none border-beige-dark bg-transparent !outline-none focus:ring-0 focus:ring-offset-0 focus-visible:ring-0 focus-visible:ring-offset-0"
                      placeholder="Message"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="col-span-full pt-2">
            <FormField
              control={form.control}
              name="marketingOptIn"
              render={({ field }) => (
                <FormItem className={'marketing-optin flex items-center'}>
                  <FormControl>
                    <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                  </FormControl>
                  <FormLabel className="ml-2 cursor-pointer">
                    I&apos;d also like to receive occasional emails with news about products,
                    restaurants and suchlike, in accordance with Dishoom&apos;s{' '}
                    <Link href="/privacy-policy" className="hover-no-underline">
                      Privacy Policy
                    </Link>
                    .
                  </FormLabel>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="col-span-full pt-5">
            <Button
              className="mt-4 w-full text-white"
              variant="primary"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? <Icon variant="spinner" /> : 'Send'}
            </Button>
          </div>
          {response?.data && (
            <div className="col-span-full pt-3">
              <p>Successfully submitted</p>
            </div>
          )}

          {isError && (
            <div className="col-span-full pt-3">
              <p className="text-red">{isError}</p>
            </div>
          )}
        </form>
      </Form>
    </div>
  )
}
