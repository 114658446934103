import Image from 'next/image'
import { PortableText, type PortableTextBlock, type PortableTextComponents } from 'next-sanity'

import { cn } from '@/lib/utils'

import TooltipOverlay from '../ui/tooltip-overlay'
import CallToAction from './call-to-action'

export function CustomPortableText({
  paragraphClasses,
  firstLetterClasses,
  blockquoteClasses,
  value,
  className,
}: {
  paragraphClasses?: string
  firstLetterClasses?: string
  blockquoteClasses?: string
  value: PortableTextBlock[]
  className?: string
}) {
  if (!value) return null

  // temp hack to fix problem
  value.forEach((block) => {
    if (!block.markDefs) block.markDefs = []
  })

  const components: PortableTextComponents = {
    block: {
      h2: ({ children }) => (
        <h2 className="h4 mt-10 xl:mt-[3.75rem] [&+:is(.h3,.h4,.h5)]:mt-2 [&+:is(.h3,.h4,.h5)]:xl:mt-3 [&+p]:mt-4 xl:[&+p]:mt-5">
          {children}
        </h2>
      ),
      h3: ({ children }) => (
        <h3 className="h5 mt-10 xl:mt-[3.75rem] [&+:is(.h3,.h4,.h5)]:mt-2 [&+:is(.h3,.h4,.h5)]:xl:mt-3 [&+p]:mt-4 xl:[&+p]:mt-5">
          {children}
        </h3>
      ),
      h4: ({ children }) => (
        <h4 className="h3 mt-10 xl:mt-[3.75rem] [&+:is(.h3,.h4,.h5)]:mt-2 [&+:is(.h3,.h4,.h5)]:xl:mt-3 [&+p]:mt-4 xl:[&+p]:mt-5">
          {children}
        </h4>
      ),
      blockquote: ({ children }) => (
        <blockquote
          className={cn(
            'gap grid list-disc grid-flow-dense grid-cols-6 gap-6 xl:grid-cols-10',
            blockquoteClasses,
          )}
        >
          <Image
            className="col-span-full justify-self-center"
            src="/assets/icons/icon-double-quote.svg"
            width="48"
            height="36"
            alt="Double quote"
          />
          <p
            className={cn(
              'prose-text col-span-full text-balance text-center xl:col-span-8 xl:col-start-2',
              paragraphClasses,
            )}
          >
            {children}
          </p>
        </blockquote>
      ),
      normal: ({ children, index }) => {
        const firstClasses = index === 0 ? firstLetterClasses : ''
        return (
          <p
            className={cn(
              'paragraph [&_a]:underline [&_a]:transition hover:[&_a]:opacity-50',
              firstClasses,
              paragraphClasses,
            )}
          >
            {children}
          </p>
        )
      },
      smallTextItalic: ({ children }) => (
        <p className="small-text mt-10 xl:mt-[3.75rem]">{children}</p>
      ),
      paragraphSmall: ({ children }) => (
        <p className="body mt-10 xl:mt-[3.75rem] [&+p]:mt-4 xl:[&+p]:mt-5">{children}</p>
      ),
      paragraphLarge: ({ children }) => (
        <p className="h6 mt-10 xl:mt-[3.75rem] [&+p]:mt-4 xl:[&+p]:mt-5">{children}</p>
      ),
      blogIntro: ({ children }) => (
        <p
          className={cn(
            'paragraph first-letter:float-left first-letter:mr-2 first-letter:text-[3.75rem] first-letter:leading-[0.8] sm:first-letter:text-[4.5rem]',
            paragraphClasses,
          )}
        >
          {children}
        </p>
      ),
    },
    marks: {
      linkExternal: ({ children, value }) => {
        return (
          <CallToAction
            className="justify-self-stretch underline transition hover:opacity-50 lg:w-auto"
            linkExternal={value}
            linkType="external"
            _type="callToAction"
          >
            {children}
          </CallToAction>
        )
      },
      linkInternal: ({ children, value }) => {
        return (
          <CallToAction
            className="underline transition hover:opacity-50 lg:w-auto"
            linkInternal={value}
            linkType="internal"
            _type="callToAction"
          >
            {children}
          </CallToAction>
        )
      },
      linkEmail: ({ children, value }) => {
        return (
          <CallToAction
            className="underline transition hover:opacity-50 lg:w-auto"
            linkEmail={value}
            linkType="email"
            _type="callToAction"
          >
            {children}
          </CallToAction>
        )
      },
      linkProduct: ({ children, value }) => {
        return (
          <CallToAction
            className="underline transition hover:opacity-50 lg:w-auto"
            linkExternal={value}
            linkType="product"
            _type="callToAction"
          >
            {children}
          </CallToAction>
        )
      },
      tooltipOverlay: ({ children, value }) => {
        return <TooltipOverlay {...value}>{children}</TooltipOverlay>
      },
    },
    list: {
      // Ex. 1: customizing common list types
      bullet: ({ children }) => (
        <ul className="paragraph gap ml-5 mt-10 list-disc space-y-3 first:mt-0 xl:mt-[3.75rem]">
          {children}
        </ul>
      ),
      number: ({ children }) => (
        <ol className="paragraph gap ml-5 mt-10 list-decimal space-y-3 marker:font-bold xl:mt-[3.75rem]">
          {children}
        </ol>
      ),
    },
    listItem: {
      // Ex. 1: customizing common list types
      bullet: ({ children }) => <li className="pl-2">{children}</li>,
      number: ({ children }) => <li className="pl-2">{children}</li>,
    },
    types: {
      // image: ({ value }: { value: Image & { alt?: string; caption?: string } }) => {
      //   return (
      //     <div className="my-6 space-y-2">
      //       <ImageBox image={value} alt={value.alt} classesWrapper="relative aspect-[16/9]" />
      //       {value?.caption && (
      //         <div className="text-sm text-gray-600 font-sans">{value.caption}</div>
      //       )}
      //     </div>
      //   )
      // },
    },
  }

  return (
    <div className={`space-y-1em ${className}`}>
      <PortableText components={components} value={value} />
    </div>
  )
}
